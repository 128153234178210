import { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Form } from '../FormRoot'
import Auth from '../MainTriage/Auth'
import CPF from '../MainTriage/CPF'
import Covid from '../MainTriage/Covid'
import FormAwnser from '../MainTriage/FormAwnser'
import Gender from '../MainTriage/Gender'
import Pregnancy from '../MainTriage/Pregnancy'
import Symptoms from '../MainTriage/Symptoms'
import SymptomsList from '../MainTriage/SymptomsList'
import TestCovid from '../MainTriage/TestCovid'
import User from '../MainTriage/User'
import ChronicDisease from '../SharedQuestions/ChronicDisease'
import { Modal } from '../components/Modal/Modal'
import { useModal } from '../components/Modal/use-modal'
import api from '../services/api'
import CovidProtocol from './Covid/index'
import DepressionProtocol from './DepressionAnxiety'
import DiarrheaProtocol from './DiarrheaProtocol'
import EarProtocol from './EarPain'
import GenericProtocol from './GenericProtocol'
import HeadacheProtocol from './Headache'
import InferiorBelly from './InferiorBelly'
import SuperiorBelly from './SuperiorBelly'
import ThroatProtocol from './ThroatPain'
import UrineProtocol from './UrineProtocol'
import { ModalContainer } from './styles'
import QuestionsOrInfo from '../MainTriage/QuestionsOrInfo'
import HowCanIHelp from '../MainTriage/HowCanIHelp'
import Triage from '../MainTriage/Triage'
// import env from "react-dotenv";

const Home = () => {
  const clientId = 1;
  // const api_key = env.REACT_APP_API_KEY;

  const schema = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    phone: yup
      .string()
      .required('Celular Whatsapp com DDD é obrigatório')
      .matches(/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/, {
        message: '(xx) xxxxx-xxxx',
      }),
    birthDate: yup
      .string()
      .required('Data de nascimento é obrigatória')
      .matches(
        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
        { message: 'A data precisa estar no formato DD/MM/AAAA' }
      ),
    // email: yup.string().required("E-mail é obrigatório").email("E-mail deve ser válido").matches("@", {message: "E-mail deve ser válido"}),
  })

  const [severity, setSeverity] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const lockScroll = useCallback(() => {
    document.body.style.overflow = 'hidden'
  }, [])

  const onSubmit = (triage) => {
    // Form.text needs to have a different name to work, so it removes the extra key generated by the text
    if (triage['associatedSymptomOthers']) {
      delete triage.associatedSymptomOthers
    }
    delete triage.mainSymptom
    delete triage.userUpdated
    delete triage.auth

    // removes duplicate data
    delete triage.name
    delete triage.phone
    // delete triage.email
    delete triage.birthDate
    delete triage.address
    delete triage.addressNumber
    delete triage.cep
    delete triage.city
    delete triage.state
    console.log(triage)

    // api_key must be on env file
    setIsLoading(true)
    try {
      api
        .post(
          `/PostScreening?api_key=F792FD61-74F4-407B-9C0C-FC8171C41F88`,
          triage,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log(res)
          if (triage.severity === 1) {
            setSeverity('green')
          } else if (triage.severity === 2) {
            setSeverity('blue')
          } else if (triage.severity === 3) {
            setSeverity('yellow')
          } else if (triage.severity === 4) {
            setSeverity('red')
          } else {
            setSeverity('gray')
          }
          setTimeout(() => {
            window.scrollBy({ top: window.outerHeight, behavior: 'smooth' })
          }, 500)
          setDisabled(true)
          lockScroll()
        })
    } catch (err) {
      console.log('axios err', err)
    }
    setIsLoading(false)
  }

  const [isValidCPF, setIsValidCPF] = useState(false)
  const [invalidCPF, setInvalidCPF] = useState(false)
  const [user, setUser] = useState()
  const [isDisabled, setIsDisabled] = useState(false)

  const { isOpen, openModal, closeModal, changeOpen } = useModal()
  useEffect(() => openModal(), [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onChangeOpen={changeOpen}
        size={{ width: '400px', height: '400px' }}
      > 
        <ModalContainer>
          <div>
            <img
              src='https://s3.amazonaws.com/laura.covid/images/Prefeitura%20de%20Guarapuava_1644440070317.png'
              alt='Sara'
              style={{ height: '46px', marginTop: '30px', marginRight: '38px' }}
            />
          </div>
          <h3
            style={{
              color: '#1b255e',
              fontSize: '18px',
              fontFamily: 'Roboto,sans-serif',
              fontWeight: '900',
              lineHeight: '1.75rem',
              marginTop: '20px',
              marginBottom: '10px',
              opacity: '1',
            }}
          >
            Olá, seja bem-vindo à Triagem Virtual.
          </h3>
          <p
            style={{
              fontSize: '14px',
              fontFamily: 'Roboto,sans-serif',
              fontWeight: '500',
              marginBottom: '15px',
            }}
          >
            Seguindo o passo a passo do vídeo abaixo, você dará início ao seu
            atendimento remoto com profissionais da saúde.
          </p>
          <div
            className='iframe-container'
            style={{
              overflow: 'hidden',
              paddingTop: '100%',
              position: 'relative',
            }}
          >
            <iframe
              title='guide'
              src='https://www.youtube.com/embed/Bm9ywftmKK8'
              style={{
                border: '0',
                height: '50%',
                left: '0',
                position: 'absolute',
                top: '0',
                width: '100%',
              }}
            ></iframe>
          </div>
        </ModalContainer>
      </Modal>
      <Form.Root onSubmit={onSubmit} validationSchema={schema}>
        {({ watch, setValue, getValues }) => {
          const getData = async () => {
            setIsLoading(true)
            try {
              let cpf = watch('cpf')
              cpf = cpf.replace(/\D/g, '')
              const { data } = await api.get(
                `/GetPatient?Cpf=${cpf}&api_key=F792FD61-74F4-407B-9C0C-FC8171C41F88&clientId=${clientId}`,
                {
                  headers: {
                    'content-type': 'application/json',
                  },
                }
              )
             setValue('isAttendance', data.attendance)
              // if (data.attendance === true) {
              //     setInvalidCPF("Verificamos que você já realizou uma triagem e esta aguardando o atendimento, nossa equipe logo entrará em contato!")
              //     setTimeout(() => {
              //         window.scrollBy({ top: window.outerHeight, behavior: "smooth" })
              //     }, 200);
              // }
              // else
              if (
                data.cpf !== null
                //  && data.attendance === false
              ) {
                setUser({
                  name: data.name,
                  phone: data.phone,
                  birthDate: data.birthDate,
                  address: data.address,
                  addressNumber: data.addressNumber,
                  cep: data.cep,
                  city: data.city,
                  state: data.state,
                })
                setInvalidCPF(false)
                setIsValidCPF(true)
                setIsDisabled(true)
                setTimeout(() => {
                  window.scrollBy({
                    top: window.outerHeight,
                    behavior: 'smooth',
                  })
                }, 200)
              } else {
                setInvalidCPF(
                  'CPF inválido, converse com a central de atendimento da prefeitura.'
                )
                setTimeout(() => {
                  window.scrollBy({
                    top: window.outerHeight,
                    behavior: 'smooth',
                  })
                }, 200)
              }
            } catch (err) {
              console.log(err)
            }
            setIsLoading(false)
          }

          const isAuth = watch('auth') || false
          const questionsOrInfo = watch('questionsOrInfo') || false
          const isUserUpdated = watch('userUpdated') || false
          const gender = watch('gender')
          const isPregnant = watch('isPregnant') || false
          const isThereSymptom = watch('mainSymptom') || false
          const isCovid = watch('covid') || false
          const testCovid = watch('testCovid')
          const disease = watch('disease') || false
          const isAttendance = watch('isAttendance') || false
          const newTriage = watch('triage')

          return (
            <>
              <Auth setValue={setValue} />
              {isAuth && (
                <CPF
                  handleClick={getData}
                  error={invalidCPF}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                />
              )}
              {isValidCPF && (
                <User
                  user={user}
                  setUser={setUser}
                  setValue={setValue}
                  watch={watch}
                />
              )}
              {isUserUpdated && isAttendance === true && <Triage setValue={setValue} severity={severity} setSeverity={setSeverity}/>}
              {isUserUpdated && isAttendance === false && <QuestionsOrInfo setValue={setValue} />}
              {newTriage && <QuestionsOrInfo setValue={setValue} />}
              {questionsOrInfo === 'Sim' && 
                <HowCanIHelp 
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
              {questionsOrInfo === 'Não' && <Gender setValue={setValue} />}
              {gender === 'Feminino' && <Pregnancy setValue={setValue} />}
              {(gender === 'Masculino' && <Symptoms setValue={setValue} />) ||
                (isPregnant && <Symptoms setValue={setValue} />)}
              {isThereSymptom === 'Sim' && <Covid setValue={setValue} />}
              {isThereSymptom === 'Não' && (
                <ChronicDisease
                  watch={watch}
                  setValue={setValue}
                  onSubmit={onSubmit}
                  getValues={getValues}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {isCovid === 'Sim' && <TestCovid setValue={setValue} />}
              {testCovid && (
                <CovidProtocol
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {isCovid === 'Não' && <SymptomsList setValue={setValue} />}
              {disease === 'headache' && (
                <HeadacheProtocol
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {disease === 'earPain' && (
                <EarProtocol
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {disease === 'throatPain' && (
                <ThroatProtocol
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {disease === 'superiorBellyPain' && (
                <SuperiorBelly
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {disease === 'inferiorBellyPain' && (
                <InferiorBelly
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {disease === 'urinePain' && (
                <UrineProtocol
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {disease === 'diarrhea' && (
                <DiarrheaProtocol
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {disease === 'others' && (
                <GenericProtocol
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {disease === 'depressionAnxiety' && (
                <DepressionProtocol
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {severity && <FormAwnser severity={severity} />}
            </>
          )
        }}
      </Form.Root>
    </>
  )
}

export default Home
